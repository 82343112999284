<template>
  <div id="app">
    <!--不要销毁-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive&&$store.state.homelistKeepAlive==true"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive||$store.state.homelistKeepAlive==false"></router-view>
    <tabbar></tabbar>
  </div>
</template>
<script>
import Tabbar from 'components/tabbar/Tabbar'

export default {
  name: 'App',
  components: {
    Tabbar
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style>
@import 'assets/css/reset.css';
@import 'assets/font-al4/iconfont.css';
@import 'assets/fonts/css/all.css';

body {
  background-color: #fff;
}

.amap-sug-result {
  z-index: 99999 !important;
}

.group-space-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10rpx 10rpx;
}
</style>
