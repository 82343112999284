<template>
  <nav id="nav-tab">
    <ul id="nav-ul">
      <li
        v-for="(item, index) in names"
        v-bind:key="item"
        class="nav-li"
        :class="{ active: currIndex === index }"
        @click="liclick(index)"
      >
        <i :class="nclass[index]"></i>

        <p class="nav-p">{{ item }}</p>
      </li>
      <li id="nav-li-overlay"></li>
    </ul>
    <!--:style="{'le ft':currIndex*93+'px'-->
  </nav>
</template>

<script>
export default {
  name: 'Tabbar',
  data() {
    return {
      names: ['首页', '商家', '发布', '消息', '我的'],
      routername: ['/home', '/company', '/more', '/news', '/user'],

      // nclass:['fas ','fas ','fas fa-plus-square','fas ','fas '],
      nclass: ['fas fa-home', 'fas el-icon-s-shop', 'fas fa-plus-square', 'fas fa-comment-dots', 'fas fa-user'],

      currIndex: 0
    }
  },
  computed: {},
  methods: {
    liclick(index) {
      //变色
      //跳转
      this.currIndex = index

      var leftx = document.getElementById('nav-li-overlay')

      var parentleft = document.getElementById('nav-ul')

      leftx.style.left = this.currIndex * (parentleft.offsetWidth / 5) + 'px'

      //取消首页缓存
      this.$store.state.homelistKeepAlive=false;

      //跳转
      this.$router.push(this.routername[index]).catch(() => {})
    }
  }
}
</script>

<style scoped>
#nav-ul {
  background-color: #fff;
  display: flex;
  /*justify-content: center;*/
  /*align-items: center;*/
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
}

.nav-li {
  position: relative;
  font-size: 12px;
  color: #00552b;
  height: 49px;
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.nav-li i {
  font-size: 16px;
  display: block;
  margin-bottom: 4px;
}
.nav-p {
  color: #00552b;
}

#nav-li-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 49px;
  width: 20%;
  /*background-color: #e4f2ff;*/
  background-color: #b8d8c8;
  transition: 0.1s;
}

.active p {
  display: block;
}
</style>
