import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bool: false,
    baseURL: 'https://www.sch888.cn',
    userlist: {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    screenWidth: document.body.clientWidth,
    screenHeight: document.body.clientHeight,
    userId: localStorage.getItem('userId'),
    userInfoJSON: localStorage.getItem('userInfo'),
    typeIndex: 0,
    typeListLenght: 0,
    homelistKeepAlive:false,
    tradeListPageIndex: 0
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token.token) //存储token
    },
    delToken(state) {
      state.token = ''
      localStorage.removeItem('token') //删除token
    },
    addTradeListPageIndex(state) {
      state.tradeListPageIndex++
    },
    initTradeListPageIndex(state) {
      state.tradeListPageIndex = 1
    },
    setTypeIndex(state, typeIndex) {
      state.typeIndex = typeIndex
    },
    setTypeListLenght(state, lenght) {
      state.typeListLenght = lenght
    }
  },
  actions: {},
  modules: {}
})
