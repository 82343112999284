import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//路由懒加载
const Home = () => import('views/home/Home')
const More = () => import('views/more/More')
const News = () => import('views/news/News')
const User = () => import('views/user/User')
const Login = () => import('views/user/login/Login')
const HomeList = () => import('views/home/homechild/HomeList')
const FollowList = () => import('views/home/follow/FollowList')
const HomeDetail = () => import('views/home/homechild/homedetail/HomeDetail')
/*user*/
const UserNote = () => import('views/user/userchild/note/UserNote')
const UserFabs = () => import('views/user/userchild/fabs/UserFabs')
const UserCollection = () => import('views/user/userchild/collection/UserCollection')
const PersonalFollow = () => import('views/user/userchild/follow/PersonalFollow')
const PersonalFans = () => import('views/user/userchild/follow/PersonalFans')
const Editdata = () => import('views/user/userchild/editdata/Editdata')
const EditCompany = () => import('views/user/userchild/editdata/EditCompany')
/*news*/
const Chat = () => import('views/news/newschild/chat/Chat')

const CollectAndLike = () => import('views/news/newschild/message/CollectAndLike')

const MessageList = () => import('views/news/newschild/MessageList')
const Company = () => import('views/company/List')
const CompanyDetail = () => import('views/company/Details')
const CompanyApply = () => import('views/company/Apply')
const CompanyPay = () => import('views/company/Pay')
const CompanyPayOk = () => import('views/company/PayOk')
const CompanyPayFail = () => import('views/company/PayFail')
const routes = [
  {
    path: '',
    redirect: '/homelist'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      // keepAlive: true
    },
    children: [
      {
        path: '',
        redirect: '/homelist'
      },
      {
        path: '/homelist',
        component: HomeList,
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/followlist',
        component: FollowList
      }
    ]
  },
  {
    path: '/more',
    component: More
  },
  {
    path: '/company',
    component: Company
  },
  {
    path: '/companyDetail/:id',
    component: CompanyDetail
  },
  {
    name: 'companyApply',
    path: '/companyApply',
    component: CompanyApply
  },
  {
    path: '/companyPay',
    component: CompanyPay
  },
  {
    path: '/companyPayOk',
    component: CompanyPayOk
  },
  {
    path: '/companyPayFail',
    component: CompanyPayFail
  },
  {
    path: '/news',
    component: News
  },
  {
    path: '/user',
    component: User,
    children: [
      {
        path: '',
        redirect: '/usernote'
      },
      {
        path: '/usernote',
        component: UserNote
      },
      {
        path: '/userfabs',
        component: UserFabs
      },
      {
        path: '/usercollection',
        component: UserCollection
      }
    ]
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/homedetail/:id',
    component: HomeDetail
  },
  {
    path: '/Chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/CollectAndLike',
    name: 'CollectAndLike',
    component: CollectAndLike
  },

  {
    path: '/personalfollow',
    name: 'personalfollow',
    component: PersonalFollow
  },
  {
    path: '/personalfans',
    name: 'personalfans',
    component: PersonalFans
  },
  {
    path: '/editdata',
    name: 'editdata',
    component: Editdata
  },
  {
    path: '/EditCompany',
    name: 'EditCompany',
    component: EditCompany
  },
  {
    path: '/MessageList',
    name: 'MessageList',
    component: MessageList
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*const originalPush = VueRouter.prototype.push

VueRouter.prototype.push=function push(location){
  return originalPush.call(this, location).catch(err => err)
}*/

/*导航守卫*/
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    return next()
  }
  const tokenStr = localStorage.getItem('token')
  if (to.path === '/usernote' && tokenStr === null) {
    next('/login')
  } else {
    next()
  }
})

export default router
