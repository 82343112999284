import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'vant/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css'
import VueResource from 'vue-resource'
import VueLazyload from 'vue-lazyload'
import waterfall from 'vue-waterfall2'
import axios from 'axios'
import vant from 'vant'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
Vue.prototype.$ajax = axios
Vue.config.productionTip = false
Vue.use(VueResource)
Vue.use(ElementUI)
Vue.use(waterfall)
Vue.use(vant)

// 生产环境 后台配置的安全密钥地址 详见官方配置文件
// window._AMapSecurityConfig = {
//   serviceHost: `xxx/_AMapService`
// }
/*图片懒加载*/
Vue.use(VueLazyload, {
  loading: require('./assets/img/ljz.gif'),
  attempt: 2
})

//引入组件
import { Message } from 'element-ui'

import AMap from 'vue-amap'
Vue.use(AMap)

AMap.initAMapApiLoader({
  key: '8de7e7b6447eb3e7e79119a24dcc8411',
  plugin: ['AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.ToolBar', 'AMap.Geocoder']
})
Vue.config.productionTip = false
window._AMapSecurityConfig = {
  securityJsCode: 'dc6f9af896129a67a101bbecc9a30db2'
}

//注意：这里使用的$message,所以在使用时候也是this.$message
Vue.prototype.$message = function(msg) {
  return Message({
    message: msg,
    duration: 1000
  })
}

new Vue({
  router,
  store,
  ElementUI,
  VueLazyload,
  render: (h) => h(App)
}).$mount('#app')

// 全局时间过滤器，将秒数转换为yyyy-m-d hh:mm:ss格式
Vue.filter('datetimeFormat', function(originVal) {
  const dt = new Date(originVal * 1000)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getMonth() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return `${y}-${m}-${d}-${hh}:${mm}:${ss}`
})

// 全局时间过滤器，将秒数转换为yyyy-m-d格式
Vue.filter('dateFormat', function(originVal) {
  const dt = new Date(originVal * 1000)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  return `${y}-${m}-${d}`
})

Vue.filter('ellipsis', function(value) {
  if (!value) return ''
  if (value.length > 18) {
    return value.slice(0, 18) + '...'
  }
  return value
})
